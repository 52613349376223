import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {isPendingAction , isRejectedAction , isFulfilledAction} from './slice.helper';
import Api from '../services/UserService';

const initialState = {
    loading: false,
    error: '',
    data: null,
}

export const registerExped = createAsyncThunk(
    "User/registerExped",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.registerExped(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const createAdm = createAsyncThunk(
    "User/createAdm",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createAdm(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const createLiv = createAsyncThunk(
    "User/createLiv",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createLiv(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const listAdmExped = createAsyncThunk(
    "User/listAdmExped",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listAdmExped(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const listAdmDriver = createAsyncThunk(
    "User/listAdmDriver",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listAdmDriver(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const listAdmDriverDispo = createAsyncThunk(
    "User/listAdmDriverDispo",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listAdmDriverDispo(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const enableAdmUser = createAsyncThunk(
    "User/enableAdmUser",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.enableAdmUser(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const disableAdmUser = createAsyncThunk(
    "User/disableAdmUser",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.disableAdmUser(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const deleteAdmUser = createAsyncThunk(
    "User/deleteAdmUser",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.deleteAdmUser(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const listAdmUsers = createAsyncThunk(
    "User/listAdmUsers",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.listAdmUsers(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)

export const updateAdmUser = createAsyncThunk(
    "User/updateAdmUser",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.updateAdmUser(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)
export const createUserApiToken = createAsyncThunk(
    "User/createUserApiToken",
    async (data, { rejectWithValue }) => {
        try {
            const res = await Api.createUserApiToken(data)
            return res.data;
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            return rejectWithValue(message)
        }
    }
)




const UserSlice = createSlice({
    name: "User",
    initialState,
    reducers: {
        clear: (state, action) => {
            state.loading = false;
            state.error = '';
            state.data = null;
        },

    },
    extraReducers: builder => {
        builder

            .addMatcher(isPendingAction, (state, action) => {
                state[action.meta.requestId] = 'pending'
                state.loading = true;
                state.error = '';
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state[action.meta.requestId] = 'rejected'
                state.loading = false;
                state.error = action.payload;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state[action.meta.requestId] = 'fulfilled'
                state.loading = false;
                state.error = '';
                state.data = action.payload;
            })

    }
})

const { reducer } = UserSlice;
export const { clear } = UserSlice.actions;
export default reducer;